import pool from '@ricokahler/pool';
import { useMutation, useQueryCache } from 'react-query';
import {
  collections, exprts, ID, moments, What,
} from 'weplayed-typescript-api';

import { logger } from 'common/utils/logger';

import {
  doCollectionMomentsUpdate, getKey as getCollectionKey,
} from 'consumer/hooks/useCollection/utils';

import { UseMomentsReturnType, UseMomentsType } from './types';
import {
  getLikeKey, getPublicationKey, getRemoveKey, getUpdateKey,
} from './utils';

export const useMoments: UseMomentsType = function useMoments() {
  const queryCache = useQueryCache();

  const like: UseMomentsReturnType['like'] = useMutation(
    moments.like,
    {
      onMutate: ({ uid, like: l }) => {
        queryCache.setQueryData(getLikeKey(uid as ID), { like: l }, { staleTime: 5000 });
      },
      onError: (error, { uid, like: l }) => {
        queryCache.setQueryData(getLikeKey(uid as ID), { like: !l }, { staleTime: 5000 });
        logger.error('Moment liking failed', { message: error.message });
      },
    },
  );

  const download: UseMomentsReturnType['download'] = useMutation(
    async ({ uid }) => {
      await exprts.create({ uid: [uid], type: What.MOMENTS });
    },
    {
      onError: (error) => {
        if (error.status !== 403) {
          logger.error('Moment download failed', { message: error.message });
        }
      },
    },
  );

  const remove: UseMomentsReturnType['remove'] = useMutation(
    moments.remove,
    {
      onSuccess: (_e, { uid }) => {
        queryCache.setQueryData(getRemoveKey(uid as ID), undefined, { staleTime: 5000 });
      },
      onError: (error) => {
        logger.error('Moment removing failed', { message: error.message });
      },
    },
  );

  const flag: UseMomentsReturnType['flag'] = useMutation(
    moments.flag,
    {
      onError: (error) => {
        logger.error('Moment flag failed', { message: error.message });
      },
    },
  );

  const pin: UseMomentsReturnType['pin'] = useMutation(
    moments.raise,
    {
      onError: (error) => {
        logger.error('Moment pin failed', { message: error.message });
      },
    },
  );

  const uncollect: UseMomentsReturnType['uncollect'] = useMutation(
    async ({ uid: momentId, collections: uids }) => {
      await pool({
        collection: uids,
        maxConcurrency: 3,
        task: async (uid) => {
          const { moments: $moments } = await collections.view({ uid });
          const momentIds = $moments
            .map(({ pk }) => pk)
            .filter((pk) => pk !== momentId);
          await doCollectionMomentsUpdate(uid, momentIds);
        },
      });
    },
    {
      onSuccess: (_r, { collections: uids }) => {
        uids.forEach((uid) => queryCache.invalidateQueries(getCollectionKey(uid)));
      },
      onError: (error) => {
        logger.error('Moment uncollect failed', { message: error.message });
      },
    },
  );

  const update: UseMomentsReturnType['update'] = useMutation(
    async (params) => { await moments.update(params); },
    {
      onSuccess: (_, { uid, moment }) => {
        queryCache.setQueryData(getUpdateKey(uid), moment, { staleTime: 5000 });
      },
      onError: (error) => {
        logger.error('Cant update moment', { message: error.message });
      },
    },
  );

  const review: UseMomentsReturnType['review'] = useMutation(
    moments.review,
    {
      onSuccess: (data, { uid }) => {
        const key = getPublicationKey(uid);
        queryCache.setQueryData(key, data);
      },
    },
  );

  return { download, flag, like, pin, remove, uncollect, update, review };
};
