// extracted by mini-css-extract-plugin
export var summary = "dea1347edfbdb835855856ac646a0e07-less";
export var photo = "_9cd8bfbfaff44cf52b3f919709fa7d28-less";
export var info = "_8cdc69f44a1228dd9ddbd4984e28a4c1-less";
export var actions = "_2954bb620f5fe73f02d2e194e229c79b-less";
export var bio = "_24c12ef92628baf581f8fe451eb93778-less";
export var follow = "_391be18ac8e6d2507bb1906635cd6d83-less";
export var bioContent = "fb45c021cecea82209efc09182a14caf-less";
export var tabs = "_286e2feecd9e09e66846b5eb41ec7262-less";
export var tab = "_7a46738c750892043191a26311e82a94-less";
export var manage = "dae699813cc4041f54b7a03ea7235456-less";
export var sport = "ba7e9955877d24de9570d5997ba739f7-less";
export var content = "d1b1cef7aa5f0806109c73887f8d12da-less";
export var topMomentHolder = "f1ec7ee389e63dffbc84c0db3fc01764-less";
export var channel = "fd8bd092b7345cefad7b65a31ed93fff-less";