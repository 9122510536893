import * as cx from 'classnames';
import * as DefaultGameCoverImg from 'images/default_game_cover.jpg';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Team } from 'weplayed-typescript-api';

import { formatDate } from 'common/utils/dates';

import { GameHeadline } from 'consumer/components/GameHeadline';
import { LiveButton } from 'consumer/components/LiveButton';
import { TeamImage } from 'consumer/components/TeamImage';
import { CLIENT_URLS } from 'consumer/routes';

import * as s from './GameDetailedSlide.m.less';
import { Props } from './types';

const renderTeam = (team: Team, score?: number): React.ReactNode => (
  <div className={s.team}>
    <TeamImage
      team={team}
      size="x-large"
      className={s.teamImage}
    />
    <span className={s.teamName}>{team.name}</span>
    <span className={s.teamScore}>{score}</span>
  </div>
);

export const GameDetailedSlide: React.FC<Props> = function GameDetailedSlide({
  game, title = 'FEATURED GAME',
}) {
  if (!game) {
    return null;
  }

  const {
    cover, name, name_extra, team1, team2, live_now,
    team1_score, team2_score, sport: { slug },
  } = game;

  return (
    <div className={cx(s.root, cover && s.withCover, live_now && s.live)}>
      <div className={s.header}>
        <h2>{title}</h2>
        <span>{!live_now && formatDate(game.date_played)}</span>
      </div>
      <GameHeadline className={s.top} game={game} hideDate disableLinks />
      <div className={s.body}>
        <div className={cx(s.cover, slug)}>
          <img src={cover || DefaultGameCoverImg} alt={name} />
          <div className={s.shim} />
        </div>
        {name_extra && <div className={s.extra}>{name_extra}</div>}
        <div className={s.score}>
          {renderTeam(team1, team1_score)}
          <div className={s.at}>at</div>
          {renderTeam(team2, team2_score)}
        </div>
        {live_now && <LiveButton live className={s.liveButton} collapse={false} />}
      </div>
      <Link to={CLIENT_URLS.GAME.VIEW.toPath({ uid: game.pk })} />
    </div>
  );
};
