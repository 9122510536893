// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_49fdaa6a25d0b02af8d81111c780262e-less";
export var backgroundGrayGradient = "_3a4700e346eef07228ae308445c66e91-less";
export var tileImage = "_0469e8234d6ac17bb2a531c30f936ef7-less";
export var small = "_20e75833529847567513ab6849e3cd9d-less";
export var root = "c4924d6b896f50ee994012637b8aee33-less";
export var wl = "_215a14bbcf87b85bc25108cf6fbe18db-less";
export var content = "f571834b4a79a46853f10f8fc5a91677-less";
export var description = "_022c1df3cce08c79033500e1f68018d7-less";
export var moment = "_6b7e59acca1ad5b61e8023f88689bae8-less";
export var ads = "_2838fad9fbc237fbc50de4f124fb8516-less";
export var left = "_3998f8a1234f80149e343509bd274342-less";
export var right = "_108e12458426a3487d7b40750b15c86d-less";
export var ad = "e29b8a1fe0c08c83f5e6c35d55253205-less";