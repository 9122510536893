// extracted by mini-css-extract-plugin
export var root = "b2ad607e5bdc8fe4d5df59ae2a75f664-less";
export var header = "ee2aef1907722a4fbe9d7c79b2a3dfeb-less";
export var top = "d69f725f176967eed186ec5c88b418a5-less";
export var live = "df33fb51d081d3b4f1b0fde39267ae71-less";
export var body = "_1362f14220dd6db5aab9287e74992e02-less";
export var cover = "c8dcd31b02bac5633afa5dc2bc8bf69a-less";
export var withCover = "_88b13f2eef43b48a34e29d03d27e736b-less";
export var shim = "_160c772d86a97b7b12488537a97c5c9b-less";
export var extra = "a2819b8d30d58661b7a358665cfb4f42-less";
export var score = "_43abb6afe12205a46158dbef146d080b-less";
export var team = "_260e3e1796f21436412dc6d6d0df2b7e-less";
export var teamImage = "e80d0f58070cc2466910541296fa4d39-less";
export var teamName = "_1873ecabf3785b8a1d443e2afd8647d7-less";
export var teamScore = "_555e2bbb37c7acb610ac2efcadcbb1c1-less";
export var at = "_4f761e85844adfd681a696fbc69a6434-less";
export var liveButton = "_8826758c46fef5782b2baae8e65f7312-less";