// extracted by mini-css-extract-plugin
export var description = "be195b3abf8506a0ba4252806d8df4b4-less";
export var userHighlightName = "b7b2a1a6a25fa51c5c8109b4cf0d6322-less";
export var root = "fbcf0cf26bd0d7d85d49f44c8d1ec3a7-less";
export var poster = "_17fefc3fdfe0a8e22bbec1f06e20e45a-less";
export var headline = "e12d3462ff59d359e4162238f226083f-less";
export var divider = "_1e206782a12a9c23780b219839ede5a3-less";
export var player = "bfdc65ef6a7dca63a32332c03e7523e7-less";
export var placeholder = "_02e0a2a3a2412ae6092a53fc02279103-less";
export var adjustContainer = "a097a6f2a252e14593ab228ba0863f73-less";
export var videoTrimmer = "c5bc49c2dfa1aeb31a86f245c15ac05e-less";
export var buttons = "_333d996d17a90ed598fab59918bcc764-less";
export var button = "bdb5590ccd24c9370f61e2a04862aa15-less";
export var controls = "_2eaf4a81b18cbe50244ac6b0d30b17fe-less";