// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "cf6595905c20555aa1176b5a7524c181-less";
export var backgroundGrayGradient = "_89984821179613f3b908edf3377ac491-less";
export var tileImage = "b54c88c86c2e569912d21f557bbd8e44-less";
export var small = "_22be09d828d0a94d9b799f2b62c5d73c-less";
export var root = "_283438674ad0a3c87e971426adf2a695-less";
export var link = "_7448079a5100252025da9bd997100500-less";
export var preview = "ff2b22c1b9dfd40dde244a25385ee9ed-less";
export var description = "de3cdcd16700d5b82976dd28bef58259-less";
export var userHighlightName = "fe31397e4b3d796acc252c19b5725f14-less";
export var stats = "bdebe7cf288572409f3ff7e75058046b-less";
export var game = "bed13a5c6104f3cf1c3e4079c5a41d18-less";
export var fade = "e79bf32879d9cc989225f6e5852ea961-less";