import { ReactComponent as Email } from '@mdi/svg/svg/email.svg';
import { ReactComponent as Facebook } from '@mdi/svg/svg/facebook.svg';
import { ReactComponent as LinkImg } from '@mdi/svg/svg/link.svg';
import { ReactComponent as ShareVariant } from '@mdi/svg/svg/share-variant.svg';
import { ReactComponent as Twitter } from '@mdi/svg/svg/twitter.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Clipboard from 'react-clipboard.js';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { toast } from 'react-toastify';
import { What } from 'weplayed-typescript-api';

import { ShareTarget } from 'common/types';
import { EVENTS, weplayedEvent } from 'common/utils/events';
import { getVisitorId } from 'common/utils/visitorId';

import { TooltipButton } from 'consumer/components/TooltipButton';

import * as s from './Share.m.less';
import { Props } from './types';

const hidden = { display: 'none' };

export class Share extends React.PureComponent<Props> {
  protected button: React.RefObject<HTMLButtonElement> = React.createRef();

  static defaultProps: Partial<Props> = {
    services: ['facebook', 'twitter', 'copy', 'email'],
  };

  protected handleButtonShare = (): void => {
    if (window.navigator.share) {
      window.navigator
        .share(this.getMeta('native'))
        .then(() => this.props.onShare && this.props.onShare('native'))
        .catch((e) => {
          if (e.message !== 'Abort due to cancellation of share.') {
            throw e;
          }
        });
    }
  };

  protected handleMenuShare = (type: ShareTarget) => (): Promise<void> => {
    if (type === 'copy') {
      toast.success('Link was copied to your clipboard');
    }

    weplayedEvent({
      context: this.props.context,
      entity_type: this.props.meta.type,
      entity_id: [this.props.meta.pk],
      type: EVENTS.SHARE,
      service: type,
      stats: { private: 0, public: 1 },
    });

    if (this.props.onShare) {
      return Promise.resolve(this.props.onShare(type));
    }

    return Promise.resolve();
  };

  protected handleMenuHide = (): void => this.button.current.click();

  // eslint-disable-next-line class-methods-use-this
  protected emailLink = (meta: ShareData): string => {
    const body = `${meta.text}\n${meta.url}\n`;

    return `mailto:?subject=${encodeURIComponent(meta.title)}&body=${encodeURIComponent(body)}`;
  };

  protected getMeta = (target: ShareTarget): ShareData => {
    const { userId, meta: { type, meta } } = this.props;
    const visitorId = getVisitorId();

    const utm: string[] = ['utm_source=weplayed'];

    switch (target) {
      case 'email': {
        utm.push('utm_medium=email_share');
        break;
      }

      case 'facebook': {
        utm.push('utm_medium=fb_share');
        break;
      }

      case 'native': {
        utm.push('utm_medium=native_share');
        break;
      }

      case 'twitter': {
        utm.push('utm_medium=tw_share');
        break;
      }

      default:
        utm.push('utm_medium=other_share');
    }

    if (userId) {
      utm.push(`utm_term=u-${userId}`);
    } else if (visitorId) {
      utm.push(`utm_term=v-${visitorId}`);
    }

    switch (type) {
      case What.ATHLETES: {
        utm.push('utm_content=at-00');
        break;
      }

      case What.CHANNELS: {
        utm.push('utm_content=ch-00');
        break;
      }

      case What.COLLECTIONS: {
        utm.push('utm_content=pl-00');
        break;
      }

      case What.MOMENTS: {
        utm.push('utm_content=mo-00');
        break;
      }

      default:
        throw new Error(`Unknown type ${type} to share given`);
    }

    return {
      ...meta,
      url: `${meta.url}${(meta.url.indexOf('?') !== -1 && '&') || '?'}${utm.join('&')}`,
    };
  };

  protected wrapMeta = (
    target: ShareTarget,
    cb: (target: ShareTarget, meta: ShareData) => React.ReactNode,
  ): React.ReactNode => cb(target, this.getMeta(target));

  protected renderPopup = (): JSX.Element => (
    <Popover
      className={s.menu}
      id="share-popover"
      onClick={this.handleMenuHide}
    >
      {this.props.services.map((service) => {
        switch (service) {
          case 'facebook': return this.wrapMeta('facebook', (target, meta) => (
            <button
              className={cx(s.button, this.props.withText && s.withText)}
              data-test-id="share-facebook"
              key={service}
              onClick={this.handleMenuShare(target)}
              type="button"
            >
              <FacebookShareButton
                quote={meta.text}
                url={meta.url}
              >
                <Facebook />
                {this.props.withText && <>Facebook</>}
              </FacebookShareButton>
            </button>
          ));

          case 'twitter': return this.wrapMeta('twitter', (target, meta) => (
            <button
              className={cx(s.button, this.props.withText && s.withText)}
              data-test-id="share-twitter"
              key={service}
              onClick={this.handleMenuShare(target)}
              type="button"
            >
              <TwitterShareButton
                title={meta.text}
                url={meta.url}
              >
                <Twitter />
                {this.props.withText && <>Twitter</>}
              </TwitterShareButton>
            </button>
          ));

          case 'email': return this.wrapMeta('email', (target, meta) => (
            <a
              className={cx(s.button, this.props.withText && s.withText)}
              data-test-id="share-email"
              href={this.emailLink(meta)}
              key={service}
              onClick={this.handleMenuShare(target)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Email />
              {this.props.withText && <>Email</>}
            </a>
          ));

          case 'copy': return this.wrapMeta('copy', (target, meta) => (
            <Clipboard
              className={cx(s.button, this.props.withText && s.withText)}
              data-test-id="share-copy"
              data-clipboard-text={meta.url}
              key={service}
              onSuccess={this.handleMenuShare(target)}
            >
              <LinkImg />
              {this.props.withText && <>Copy</>}
            </Clipboard>
          ));

          default:
            return null;
        }
      })}
    </Popover>
  );

  render(): JSX.Element {
    return (
      <OverlayTrigger
        trigger="click"
        overlay={!window.navigator.share ? this.renderPopup() : <div style={hidden} />}
        placement={this.props.placement}
        onToggle={this.props.onToggle}
        rootClose
      >
        {this.props.tooltip ? (
          <TooltipButton
            aria-label="Share"
            className={s.root}
            data-test-id="share-button"
            onClick={this.handleButtonShare}
            ref={this.button}
            tooltip={this.props.tooltip}
          >
            {this.props.children}
            <ShareVariant />
          </TooltipButton>
        ) : (
          <button
            aria-label="Share"
            className={s.buttonVariant}
            data-test-id="share-button"
            onClick={this.handleButtonShare}
            ref={this.button}
            type="button"
          >
            <ShareVariant />
            {this.props.children}
          </button>
        )}
      </OverlayTrigger>
    );
  }
}
