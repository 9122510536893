// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_6e1ce374efebb3faea98f28ed38c177a-less";
export var backgroundGrayGradient = "_0be8c6777fb370bc5abf6049479ca44d-less";
export var tileImage = "d9fc323f187b22c2b5f894323d1d9c10-less";
export var small = "_6979f866c7092484ffb40be6f59c1b40-less";
export var header = "b6d4abf35408068a4fd56216938a6287-less";
export var actions = "_03769e41b7bb01fbfeb060a766623953-less";
export var name = "_5d5ddc18e9520f7f1f401587a3ac2375-less";
export var counter = "b7e0d596ae7603d1e0ba6229167a3f2b-less";
export var bold = "_6e0acf2ba4d2d1e43fb5f0f56656ebbb-less";
export var action = "_424792bea1ec2309f36347d2bceb5b23-less";
export var creator = "_5ff00f2eff897d19d4e097c9ab0986a2-less";
export var dedicated = "_262000ee119d32dfbd489d57a573be54-less";
export var game = "_3bfd5aeef5732847612ffa2f0529dd18-less";
export var scrolled = "dc13cec6d7c0feb642f1fb2e9009e10d-less";