import * as React from 'react';

import * as ACRO from './icons/acro.png';
import * as BASE from './icons/baseball.png';
import * as BASK from './icons/basketball.png';
import * as FHOC from './icons/field-hockey.png';
import * as FOOT from './icons/football.png';
import * as HOCK from './icons/hockey.png';
import * as LAX from './icons/lacrosse.png';
import * as ROW from './icons/rowing.png';
import * as RUG from './icons/rugby.png';
import * as SOCC from './icons/soccer.png';
import * as SOFT from './icons/softball.png';
import * as SWIM from './icons/swim.png';
import * as TENN from './icons/tennis.png';
import * as TF from './icons/track-field.png';
import * as VOLL from './icons/volleyball.png';
import * as WPOLO from './icons/wpolo.png';
import * as WRSTL from './icons/wrstl.png';
import { Mapping, Props } from './types';

const mapping: Mapping = {
  MCBASE: BASE,
  MCBASK: BASK,
  MCFOOT: FOOT,
  MCHOCK: HOCK,
  MCLAX: LAX,
  MCROW: ROW,
  MCRUG: RUG,
  MCSOCC: SOCC,
  MCSWM: SWIM,
  MCTENN: TENN,
  MCTF: TF,
  MCVOLL: VOLL,
  MCWPOLO: WPOLO,
  MCWRSTL: WRSTL,
  WCACRO: ACRO,
  WCBASK: BASK,
  WCFHOC: FHOC,
  WCHOCK: HOCK,
  WCLAX: LAX,
  WCROW: ROW,
  WCRUG: RUG,
  WCSOCC: SOCC,
  WCSOFT: SOFT,
  WCSWM: SWIM,
  WCTENN: TENN,
  WCTF: TF,
  WCVOLL: VOLL,
  WCWPOLO: WPOLO,
  WCWRSTL: WRSTL,
};

const SportIcon: React.FunctionComponent<Props> = function SportIcon({
  sport, alt = '', ...props
}: Props): JSX.Element {
  return <img src={mapping[sport]} alt={alt} {...props} />;
};

export default SportIcon;
